import {Heading, Text, Progress, Box, Image} from "@chakra-ui/react";
import LockedIcon from '../../icons/LockedIcon'
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import lockedSegment from '../../img/lockedSegment.png';
import {fetchCourseContent, getCourseContent} from "features/course/courseContentSlice";
import CheckIcon from "../../icons/CheckIcon";
import {cn} from "app/helpers";
import {useDispatch, useSelector} from "react-redux";
import {useCourseContentData} from "features/course/courseHooks";
import Like from "components/likes/Like";
import Button from "buttons/Button";
import ReplayIcon from "icons/ReplayIcon";

export default function SegmentCard({id, vertical = false}) {
  const classes = cn('segment');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useCourseContentData(id);
  const moduleData = useCourseContentData(data?.module?.id);
  const lastChild = useSelector((state) => {
    const children = data?.children || [];
    const child = children.find(activity => activity.locked === false && activity.progress.complete < 1);
    return getCourseContent(state, child?.id)
  });
  const {progress, locked, content} = data;
  const isComplete = progress?.complete === 1;
  const isStarted = progress && progress.started;
  const isActive = !locked && isStarted && progress && progress.complete < 1;
  const thumbnail = lastChild?.content?.thumbnail?.file?.url || content?.thumbnail?.file?.url || lockedSegment;

  const goToCourseContent = (e) => {
    if (!locked) {
      e.stopPropagation();
      navigate(`/course/content/${id}`)
    }
  }

  useEffect(() => {
    if (!lastChild.isSuccess && lastChild.id) {
      dispatch(fetchCourseContent(lastChild.id));
    }
  }, [lastChild])

  return (
    <Box onClick={goToCourseContent} className={classes({'card': true, vertical, locked, complete: isComplete, active: isActive}, ['box'])}>
      {data.isSuccess && <>
        <Box className={classes('thumbnail')}>
          <Image src={locked ? lockedSegment : thumbnail}/>
        </Box>
        <Box className={classes('content')}>
          <Box className={classes('body')}>
            <Heading className={classes('title')}>{content?.title}</Heading>
            {moduleData && <Text className={classes('module')}>{moduleData.content?.title}</Text>}
          </Box>
          <Box className={classes('footer')}>
            {!locked && <Box className={classes('actions')}>
              {!isStarted && <Button color={'orange'} onClick={goToCourseContent}>Get started</Button>}
              {isActive && <Button color={'orange'} onClick={goToCourseContent}>Resume</Button>}
              {isComplete &&
                <Button variant={'outline'} leftIcon={<ReplayIcon/>} onClick={goToCourseContent}>Restart</Button>}
            </Box>}
            {!locked && <Like entityId={id} entityType={'course_content'} variant={'bookmark'} />}
            <Box className={classes('progress')}>
              {locked &&
                <>
                  <LockedIcon size={16}/>
                  <Text>Locked</Text>
                </>}
              {isComplete && <>
                <CheckIcon/>
                <Text>Completed!</Text>
              </>}
              {isActive && <>
                <Progress className={classes('progressbar', ['progressbar'])} height={'8px'}
                          value={Math.ceil(progress?.complete * 100)}/>
                <Text>{Math.ceil(progress?.complete * 100)}%</Text>
              </>}
            </Box>
          </Box>
        </Box>
      </>}
    </Box>
  )
}