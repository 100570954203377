import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import authService from './authService'
import axios from "axios";

const user = JSON.parse(localStorage.getItem('drupal-oauth-token'))

const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  email: '',
  accountUpdated: false,
  passwordUpdated: false
}

export const handleApiError = (thunkAPI, error) => {
  const message = axios.isCancel(error) ? null : error.response?.data?.message || error.message || error.toString();

  return thunkAPI.rejectWithValue({
    message: message,
    status: error.response?.status,
  })
}

export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const refreshToken = createAsyncThunk('auth/resfreshToken', async (refresh_token, thunkAPI) => {
  try {
    return await authService.useRefreshToken(refresh_token)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const oneTimeLogin = createAsyncThunk('auth/oneTimeLogin', async (values, thunkAPI) => {
  try {
    return await authService.oneTimeLogin(values)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const logout = createAsyncThunk('auth/logout', async (arg, thunkAPI) => {
  try {
    authService.abort();
    return await authService.logout();
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const requestNewPassword = createAsyncThunk('auth/requestNewPassword', async (user, thunkAPI) => {
  try {
    return await authService.requestNewPassword(user)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

// @todo How is this used?
export const setSuccess = () => (email) => {
  return email
}

export const changePassword = createAsyncThunk('auth/changePassword', async (values, thunkAPI) => {
  try {
    return await authService.changePassword(values)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const setPassword = createAsyncThunk('auth/setPassword', async (values, thunkAPI) => {
  try {
    return await authService.setPassword(values)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.user = null
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.email = ''
      state.accountUpdated = false
      state.passwordUpdated = false
    },
    setSuccess: (state) => {
      state.isSuccess = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
        state.isError = false
        state.message = ''
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
        state.user = null
      })
      .addCase(refreshToken.pending, (state) => {
        state.isLoading = true
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
        state.isError = false
        state.message = ''
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
        state.user = null
      })
      .addCase(oneTimeLogin.pending, (state) => {
        state.isLoading = true
      })
      .addCase(oneTimeLogin.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
        state.isError = false
        state.message = ''
      })
      .addCase(oneTimeLogin.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
        state.user = null
      })
      .addCase(logout.fulfilled, (state) => {
        reset(state);
        state.user = null;
      })
      .addCase(requestNewPassword.pending, (state, action) => {
        state.isSuccess = false
        state.isLoading = true
      })
      .addCase(requestNewPassword.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.message = ''
        state.email = action.meta.arg.email
      })
      .addCase(requestNewPassword.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
        state.user = null
      })
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = ''
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.message = ''
        state.passwordUpdated = true
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
      })
      .addCase(setPassword.pending, (state) => {
        state.isLoading = true
      })
      .addCase(setPassword.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.message = ''
        state.accountUpdated = true
      })
      .addCase(setPassword.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
      })
  }
})

export const {reset} = authSlice.actions
export default authSlice.reducer