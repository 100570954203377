import { Flex, Image, Box, Text, useDisclosure, Collapse } from '@chakra-ui/react'
import NotesIcon from "icons/NotesIcon";
import neuroChangeSolutions from "../../img/neuroChangeSolutions.svg"
import {NavLink, useLocation} from "react-router-dom";
import ChevronDown from 'icons/ChevronDown';
import HomeIcon from 'icons/HomeIcon';
import CoursesIcon from 'icons/CoursesIcon';
import LikeIcon from "icons/LikeIcon";
import {useCoursesData} from "features/course/courseHooks";
import ResourcesIcon from "icons/ResourcesIcon";
import CompleteIcon from "icons/CompleteIcon";
import LockedIcon from "icons/LockedIcon";
import ProgressIcon from "icons/ProgressIcon";
import {cn} from "app/helpers";
import Block from "components/layout/Block";
import BulbIcon from "icons/BulbIcon";
import BookmarkIcon from "icons/BookmarkIcon";

const MenuItemInner = ({to, end, onToggle, children}) => {
    const classes = cn('menu', 'item-inner');
    return to ? <NavLink to={to} end={end} onClick={onToggle} className={classes()}>{children}</NavLink> : <div className={classes()} onClick={onToggle}>{children}</div>
}

const MenuItem = ({to, end, title, icon, children}) => {
    const location = useLocation();
    const isActive = location.pathname === to;
    const { isOpen, onToggle } = useDisclosure();
    const classes = cn('menu');

    return <div className={classes('item', {
        expanded: children && isOpen,
        active: isActive,
    })}>
        <MenuItemInner to={to} end={end} onToggle={onToggle}>
            <div className={classes('item-icon')}>{icon}</div>
            <div className={classes('item-title')}>{title}</div>
            {children && <div className={classes('item-chevron')}>
                <ChevronDown/>
            </div>}
        </MenuItemInner>
        {children && <Collapse in={isOpen}>
            <div className={classes()}>
                {children}
            </div>
        </Collapse>}
    </div>
}

const PrimaryLink = ({to, end, title, icon, children}) => {
    const { isOpen, onToggle } = useDisclosure();

    const Link = ({to, end, onToggle, children}) => {
        return to ?
          <NavLink to={to} end={end} onClick={onToggle}>
              {({isActive}) => (
                <MenuItem isActive={isActive} onToggle={onToggle} alignItems={"center"}>{children}</MenuItem>
              )}
          </NavLink> :
          <MenuItem onToggle={onToggle} alignItems={"center"}>{children}</MenuItem>
    };

    return <>
        <Link to={to} end={end} onToggle={onToggle}>
            <Box flex={'0 0 16px'}>{icon}</Box>
            <Text as='b'>{title}</Text>
            {children && <Box className={`menu-link ${isOpen && "rotate"}`} ml={'auto'}>
                <ChevronDown/>
            </Box>}
        </Link>
        {children && <Collapse in={isOpen}>
            <Flex gap={2} direction={'column'} justifyItems={'flex-end'}>
                {children}
            </Flex>
        </Collapse>}
    </>
}

const SecondaryLink = ({to, end, title, icon}) => {
    return <NavLink {...{to, end}}>
        {({isActive}) => {
            return <MenuItem isActive={isActive}>
                <Box flex={'0 0 16px'}>{icon}</Box>
                {title}
            </MenuItem>
        }}
    </NavLink>
}

export default function LeftNavDesktop() {
    const [courses] = useCoursesData();

    const printCourseLinks = () => {
        return courses.map((course, index) => {
            let icon;
            if (course?.locked) {
                icon = <LockedIcon size={16}/>;
            }
            else if (course?.progress?.complete >= 1) {
                icon = <CompleteIcon color={'navy'} size={16}/>;
            }
            else {
                icon = <ProgressIcon color={'navy'} size={16} variant={'outline'}/>;
            }

            return (
              course?.isSuccess && <MenuItem key={index} to={`/course/${course.id}`} title={course.title} icon={icon}/>
            )
        })
    }

    return (
        <>
            <Block className={'block--logo'}>
                <NavLink to={"/"}>
                    <Image mx={'auto'} maxW={'100%'} src={neuroChangeSolutions} alt="ncslogo"/>
                </NavLink>
            </Block>
            <Block className={'menu-block'}>
                <div className={'menu menu--main'}>
                    <MenuItem to={"/"} icon={<HomeIcon/>} title={"Home"}/>
                    <MenuItem icon={<CoursesIcon/>} title={"Course Material"}>
                        <MenuItem to={'/course'} title={'View courses'} end />
                        <MenuItem to={'/course/saved'} title={'Saved content'} icon={<BookmarkIcon size={16} color={'orange'} />} />
                        {courses.length > 0 && printCourseLinks()}
                    </MenuItem>
                    <MenuItem to={"/notes"} icon={<NotesIcon size={16} color={'navy'}/>} title={"Notes"}/>
                    <MenuItem to={"/resources"} icon={<ResourcesIcon size={16} color={'navy'}/>} title={"Resources"}/>
                    <MenuItem to={"/encouragements"} icon={<BulbIcon size={16} color={'navy'}/>} title={"Encouragements"}/>

                    {/* <NavLink to={"/forums"}>
                {({ isActive }) => (
                    <Flex bg={isActive ? "rgba(0, 0, 0, 0.1)" : ""} w={'100%'} borderRadius={'8px'} px={4} py={3} as='button' _hover={{bg: 'lightGreyThree.100'}} alignItems={"center"}>
                        <Box mr={3}>
                            <DiscussionIcon />
                        </Box>
                        <Text as='b'>Forums</Text>
                    </Flex>
                )}
                </NavLink>*/}
                    {/*<NavLink to={"/messages"}>
                {({ isActive }) => (
                    <Flex bg={isActive ? "rgba(0, 0, 0, 0.1)" : ""} w={'100%'} borderRadius={'8px'} px={4} py={3} as='button' _hover={{bg: 'lightGreyThree.100'}} alignItems={"center"}>
                        <Box mr={3}>
                            <MessagesIcon />
                        </Box>
                        <Text as='b'>Messages</Text>
                    </Flex>
                )}
                </NavLink>
                <NavLink to={"/inspriations"}>
                {({ isActive }) => (
                    <Flex  bg={isActive ? "rgba(0, 0, 0, 0.1)" : ""} w={'100%'} borderRadius={'8px'} px={4} py={3} as='button' _hover={{bg: 'lightGreyThree.100'}} alignItems={"center"}>
                        <Box mr={3}>
                            <InspirationIcon />
                        </Box>
                        <Text as='b'>Inspiration</Text>
                    </Flex>
                )}
                </NavLink>      */}
                </div>
            </Block>
        </>
    );
}