import {useFormik} from "formik"
import {useContext} from "react";
import { Box, Image } from '@chakra-ui/react';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import Textarea from '../Textarea';
import {ActivityContext} from "components/course/Activity/ActivityContext";
import ActivityPager from "components/course/Activity/ActivityPager";

export default function OpenQuestion({data}) {
  const {onNext} = useContext(ActivityContext);
  const { content = {}, answer } = data;
  const { title, description} = content;
  const image = content.content?.image?.file;
  const aside = image ? <Image src={image.url} alt={image.alt} /> : null;

  const onSubmit = async (values) => {
    await onNext(values.answer);
  }

  // @todo Is Formik really needed here?
  const {values, handleChange, handleSubmit} = useFormik({
    initialValues: {
      answer: answer?.value || '',
    },
    onSubmit,
  });
  const isValid = values.answer?.trim() !== '';

  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      onNext: () => {
        handleSubmit();
      },
      canSubmit: isValid,
    }}>
      <ActivityWrapper title={title} description={description} aside={aside}>
        {/* Makes textarea bottom aligned. It's supposed that parent element is flex */}
        {/* and column direction. Works on desktop only at the moment, as the parent */}
        {/* element doesn't have min-height on mobile. */}
        {/* It was initially needed in the sequence of open question activities within */}
        {/* a group with pagination, to make inputs stay in the same place when */}
        {/* navigating through the group. */}
        {/* @todo Revise activity layout - maybe it should work for other activity types. */}
        {/* @todo Make it work on mobile. */}
        <Box mt="auto" pt={4}>
          <Textarea onChange={handleChange} id={'answer'} value={values.answer} bg={'white'} placeholder="Enter text here" required={true} />
        </Box>
        <ActivityPager/>
      </ActivityWrapper>
    </ActivityContext.Provider>
  )
}
