import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys)

const outline = definePartsStyle({
  track: {
    borderRadius: '30px',
    bg: 'transparent',
    border: '1px solid #fff',
    _checked: {
      bg: 'primary.orange',
    },
  }
})

export const switchTheme = defineMultiStyleConfig({ variants: { outline }})
