import { useFormik } from "formik"
import { loginSchema } from "../schemas"
import {Navigate, useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from "react";
import { login, reset } from '../features/auth/authSlice'
import { Flex, Checkbox, Link, Center } from '@chakra-ui/react'
import { toast } from 'react-toastify'
import { Link as RouterLink } from 'react-router-dom'
import RegisterLoginLayout from "../components/layout/RegisterLoginLayout";

import FormField from "../components/Input"
import PasswordInput from "../components/PasswordInput"
import SignInFormPanel from "../components/layout/SiginInFormPanel";
import CtaButton from "../buttons/CtaButton";



export default function Login() {

const navigate = useNavigate()


const { user, isLoading, isError, isSuccess, message } = useSelector(
  (state) => state.auth
)

const dispatch = useDispatch()

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        autoClose: false,
        onClose: () => dispatch(reset())
      })
    }

    if (isSuccess && user) {
      navigate('/', {replace: true});
    }

  }, [user, isError, isSuccess, message, navigate, dispatch])


const onSubmit = async (values, actions) => {
  dispatch(login(values))
}

const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
  initialValues: {
      email: "",
      password: ""
  },
  validationSchema: loginSchema,
  onSubmit,
})


  return (
    user ? <Navigate to='/' replace /> :
     <RegisterLoginLayout>
        <SignInFormPanel>
            <form  onSubmit={handleSubmit}>
              <FormField touched={touched} errors={errors} id={"email"}  name={"email"} type={"text"} value={values.email} placeholder={""} onBlur={handleBlur} onChange={handleChange} label={"Email"} />
              <PasswordInput touched={touched} errors={errors} id={"password"} name={"password"} type={"password"} value={values.password} onBlur={handleBlur} placeholder={""} onChange={handleChange} label={"Password"}/>
              <Flex w={'100%'} justifyContent={'space-between'}>
                  <Checkbox  size='sm' fontSize={'sm'}>Keep me signed in</Checkbox>
                  <Link as={RouterLink} to='/forgot-password' textDecoration={'underline'} fontSize={'sm'} color={'other.hyperlink'}>Forgot password?</Link>
              </Flex>
              <Center mx={'auto'} mt={'46px'}>
                  <CtaButton isLoading={isLoading} type={"submit"} name={"submit"} width={'248px'} copy={"Sign In"} txColor={'white'} bgColor={'primary.orange'} className={"btn-blue w-full justify-self-center"} />
              </Center>
              
             
            </form>
        </SignInFormPanel>
       </RegisterLoginLayout>
    
  );
}