import {store} from "../../app/store";
import {fetchBlocks} from "../block/blockSlice";

export default async function mainLoader({params, request}) {
  await store.dispatch(fetchBlocks({
    region: 'cta',
    uri: '/',
  }));

  return null;
}
