import {useDispatch} from 'react-redux'
import {logout} from 'features/auth/authSlice'
import {getProfile} from 'features/profile/profileSlice';
import {toast} from 'react-toastify'
import {useEffect} from "react";
import Layout from "../components/layout/Layout";
import {
  Heading,
  Text,
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link
} from '@chakra-ui/react';
import EditProfileForm from '../components/profile/EditProfileForm';
import ChangePasswordForm from '../components/profile/ChangePasswordForm'
import EditAvatar from '../components/profile/EditAvatar';
import {useProfileData} from "features/profile/profileHooks";
import Block from "../components/layout/Block";
import Button from "buttons/Button";
import LogoutIcon from "icons/Logout";

export default function Profile() {
  const {data, isLoading, isError, isSuccess, message} = useProfileData();
  const dispatch = useDispatch()

  useEffect(() => {
    if (message) {
      isError && toast.error(message);
      isSuccess && toast.success(message);
    }

  }, [dispatch, isSuccess, isError, message])

  const handleClick = () => {
    dispatch(logout());
  }

  return (
    <Layout>
      <Block>
        <Flex my={4} gap={4} alignItems={'center'}>
          <EditAvatar data={data}/>

          <Box color={'primary.navy'}>
            {data && data.display_name ?
              <Heading size={'md'}>{data.display_name}</Heading>
              :
              ""
            }
            {data && data.profile && data.profile.company_role ?
              <Text>{data.profile.company_role}</Text>
              :
              ""}
          </Box>
        </Flex>
        <Tabs pb={'100px'}>
          <TabList>
            <Tab _selected={{borderColor: 'primary.orange'}}>
              <Heading color={'primary.navy'} size={'sm'}>Account</Heading>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <Heading pb={4} pt={8} color={'primary.navy'} size={'sm'}>Account expiration date</Heading>
              <Text color={'primary.navy'}>11 November 2022</Text>
              <Accordion py={8} allowToggle>
                <AccordionItem>
                  <AccordionButton py={4} px={0}>
                    <Heading color={'primary.navy'} size={'sm'} textAlign='left' flex='1'>Sign in details</Heading>
                    <AccordionIcon/>
                  </AccordionButton>
                  <AccordionPanel px={0}>
                    {data?.uid &&
                      <EditProfileForm isLoading={isLoading} data={data}/>
                    }
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionButton py={4} px={0}>
                    <Heading color={'primary.navy'} size={'sm'} textAlign='left' flex='1'>Update password</Heading>
                    <AccordionIcon/>
                  </AccordionButton>
                  <AccordionPanel px={0}>
                    {isSuccess &&
                    <ChangePasswordForm isLoading={isLoading}/>
                    }
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Text pb={8}>If you have any questions, please contact us at <Link color={'other.hyperlink'}
                                                                                 href={'mailto:support@ncs.com'}>support@ncs.com</Link>.</Text>
              <Button variant={'outline'} leftIcon={<LogoutIcon/>} onClick={handleClick}>Sign out</Button>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Block>
    </Layout>
  )
}