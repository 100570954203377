import {Flex, Checkbox} from "@chakra-ui/react";
import OutlineButton from "../../buttons/OutlineButton";
import {useContext, useEffect, useState} from "react";
import WordCloudRender from "./WordCloudRender";
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import {VoiceoverContext} from "../media/VoiceoverContext";
import {ActivityContext} from "components/course/Activity/ActivityContext";

export default function WordCloud({data}) {
  const {submit, onNext, onPrev, voiceover, showFooter} = useContext(ActivityContext);
  const {setVoiceover} = useContext(VoiceoverContext);
  const {answer} = data
  const options = data?.content?.content?.options || []
  const [value, setValue] = useState(answer ? answer.value.filter(item => options.filter(option => option.value === item).length > 0) : [])
  const [showAll, setShowAll] = useState(false)
  const [page, setPage] = useState(0)
  const showCloud = page > 0;
  const { cloud_description } = data?.content?.content

  const handleShowAll = () => {
    setShowAll(!showAll)
  }

  const handleSubmit = () => {
    submit(Array.isArray(value) ? value : [value]).then(action => {
      if (action.meta.requestStatus === 'fulfilled') {
        setPage(1);
      }
    });
  }

  useEffect(() => {
    if (page > 0) {
      setVoiceover(null);
    }
    else {
      setVoiceover(voiceover);
    }
  }, [page, voiceover, showFooter]);

  const handleChecked = (word) => {
    if (value.includes(word)) {
      const values = value.filter(w => w !== word)
      setValue(values)
    } else {
      setValue([word, ...value])
    }
  }

  const printCheckboxes = (list) => {
    return list.map((checkbox) => {
      const isChecked = value.includes(checkbox.value)
      return <Checkbox colorScheme={'orange'} color={'white'} onChange={() => handleChecked(checkbox.value)}
                       isChecked={isChecked} key={checkbox.value}>{checkbox.value}</Checkbox>
    })
  }

  const handleNext = () => {
    if (showCloud) {
      onNext(Array.isArray(value) ? value : [value]);
    }
    else {
      handleSubmit();
    }
  }

  const handlePrev = () => {
    if (page > 0) {
      setPage(page - 1);
    }
    else {
      onPrev();
    }
  }

  return <ActivityContext.Provider value={{
    ...useContext(ActivityContext),
    canSubmit: value.length > 0,
    onNext: handleNext,
    onPrev: handlePrev,
    totalPages: 2,
    currentPage: page,
    canNavigate: page => page === 0,
    setCurrentPage: setPage,
  }}>
    <ActivityWrapper title={data?.content?.title} description={showCloud? cloud_description : data?.content?.description}>
      {showCloud ?
        <WordCloudRender values={options}/>
        :
        <>
          <Flex my={4} direction={'column'} gap={4}>
            {printCheckboxes(options?.slice(0, showAll ? options?.length : 6))}
          </Flex>
          {options.length > 6 &&
          <OutlineButton txColor={'white'} bgColor={'primary.navy'} copy={showAll ? 'Show less' : 'Show more'}
                         onClick={handleShowAll}/>
          }

        </>
      }
    </ActivityWrapper>
  </ActivityContext.Provider>

}
