import {useEffect, useState} from "react";
import Controls from "./Controls/Controls";
import {cn} from "../../app/helpers";
import "../../styles/components/media/_audio-player.scss";
import authService from "../../features/auth/authService";
import mediaService from "../../features/media/mediaService";
import {computed, effect} from "@preact/signals-react";
import {useSelector} from "react-redux";
import {getMediaUserSettings} from "../../features/media/mediaSlice";

export default function AudioPlayer({src, captionsSrc, autoPlay, onComplete, toggleFullscreen}) {
  const classes = cn('audio-player');
  const userSettings = useSelector(state => getMediaUserSettings(state));
  const [media, setMedia] = useState(null);
  const canAutoPlay = computed(() => mediaService.allowAutoplay.value && userSettings.autoPlay && autoPlay);
  const [fileUrl, setFileUrl] = useState();
  const controlsSettings = {
    allowAutoPlay: autoPlay,
    allowFullscreen: typeof toggleFullscreen === 'function',
    toggleFullscreen: toggleFullscreen,
  };

  const handleComplete = (event) => {
    onComplete && onComplete();
  }

  useEffect(() => {
    if (media) {
      media.addEventListener('ended', handleComplete);
    }

    return () => {
      if (media) {
        media.removeEventListener('ended', handleComplete);
      }
    }
  }, [media]);

  useEffect(() => {
    setFileUrl(null);
    authService.getClient().request({
      url: src,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        'Accept': '*/*',
      },
    }).then(response => {
      const file = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = URL.createObjectURL(file);
      setFileUrl(url);
    });
  }, [src]);

  effect(() => {
    if (media && canAutoPlay.value && autoPlay) {
      !media.playing && !media.ended && media.currentTime === 0 && media.play();
    }
  });

  return <div className={classes()}>
    <video src={fileUrl} autoPlay={canAutoPlay.value} controls={false} loop={false} ref={setMedia} width={0} playsInline/>
    <Controls media={media} {...controlsSettings}/>
  </div>
}
