import { useFormik } from "formik"
import { passwordSchema } from "../schemas"
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Center, Text, Heading, VStack } from '@chakra-ui/react'
import RegisterLoginLayout from "../components/layout/RegisterLoginLayout";
import SignInFormPanel from "../components/layout/SiginInFormPanel";
import { setPassword, reset } from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify'



import Btn from "../components/Button";
import PasswordInput from "../components/PasswordInput"



export default function ChangePassword() {

  const navigate = useNavigate()
  // @todo `submitted` is never used, but `setSubmitted()` is used? How it's supposed to work?
  const [submitted, setSubmitted] = useState(false)
  const { id, timestamp, hash } = useParams()
  const dispatch = useDispatch()

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  const goBack = () => {
    dispatch(reset())
    navigate('/login')
  }

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        autoClose: false,
        onClose: () => dispatch(reset())
      })
    }
  },[isError, message])

  const onSubmit = async (values, actions) => {
    const data = {id, timestamp, hash, ...values}
    dispatch(setPassword(data)).then((res) => {
      res.status === '200' ? setSubmitted(true) : console.log(res.errors)
    } )
    
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
  initialValues: {
      password: "",
      confirmPassword: ""
  },
  validationSchema: passwordSchema,
  onSubmit,
  })


  return (

    <RegisterLoginLayout>
      <SignInFormPanel>
          { isSuccess ?
            <>
              <Heading color={'primary.navy'} as={'h2'}>Your password has been reset</Heading>
              <Center mt={'40px'}>
                <Btn onClick={goBack} name={"submit"} copy={"Sign In"} bgColor={'primary.orange'} colorScheme={'primary.navy'} />
              </Center>
            </>
          :
            <>
              <Heading color={'primary.navy'} as={'h2'}>Change your password</Heading>
              <form  onSubmit={handleSubmit}>
                <Center mx={'auto'} mb={'40px'}>
                  <VStack>
                    <PasswordInput touched={touched} errors={errors} id={"password"} name={"password"} type={"password"} value={values.password} onBlur={handleBlur} placeholder={""} onChange={handleChange} label={"Password"}/>
                    <Text fontSize={'sm'}>Must be at least 8 characters and contain at least one number, one uppercase and one lowercase letter.</Text>
                    <PasswordInput touched={touched} errors={errors} id={"confirmPassword"} name={"confirmPassword"} type={"confirmPassword"} value={values.confirmPassword} onBlur={handleBlur} placeholder={""} onChange={handleChange} label={"Confirm Password"}/>
        
                  </VStack>
                </Center>
                <Center>
                  <Btn isLoading={isLoading} type={"submit"} name={"submit"} copy={"Change Password"} txColor={'white'} bgColor={'primary.orange'}  />
                </Center>
              
              </form>
            </>
          }
      </SignInFormPanel>
    </RegisterLoginLayout>    
  );
}