import { NavLink, Link as RouterLink } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { logout, reset } from '../../features/auth/authSlice'
import  { resetProfile } from '../../features/profile/profileSlice'
import {
    MenuList,
    IconButton,
    Image,
    Text,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuDivider,
    Container,
    Link,
} from '@chakra-ui/react';
import neuroChangeSolutions from "../../img/neuroChangeSolutions.svg"
import NotesIcon from "../../icons/NotesIcon";
import ChatIcon from "../../icons/ChatIcon";
import DiscussionIcon from "../../icons/DiscussionIcon";
import InspirationIcon from "../../icons/InspirationIcon";
import FaqIcon from "../../icons/FaqIcon";
import LogoutIcon from "../../icons/Logout";
import MenuIcon from "../../icons/MenuIcon";
import BackArrow from "../../icons/BackArrow";
import ResourcesIcon from "../../icons/ResourcesIcon";
import DropdownMenu from "components/menu/DropdownMenu";
import BulbIcon from "icons/BulbIcon";
import BookmarkIcon from "icons/BookmarkIcon";

export default function TopNavMobile({headerInfo}) {

    const navigate = useNavigate()

    const dispatch = useDispatch()
    
    const handleClick = () => {
        dispatch(logout())
        .then(res => dispatch(reset()))
        .then(res => dispatch(resetProfile()))
        navigate('/')
    }
      
    return (
      <div className={'header--mobile'}>
          <Flex  w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
              {headerInfo ?
                <>
                    <Link as={RouterLink} to={headerInfo.destination ? headerInfo.destination : '/'} mr={3}>
                        <BackArrow />
                    </Link>
                    <Text color={'primary.navy'} as={'b'}>{headerInfo.title}</Text>
                </>
                :
                <Image width={'151px'} src={neuroChangeSolutions} alt={'ncs logo'} /> }
              <Menu initialFocusRef>
                  <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<MenuIcon />}
                    variant='outline'
                  />
                  <DropdownMenu>
                      <Flex direction={'column'} gap={2}>
                          <NavLink className={'dropdown-menu__item'} to={"/notes"}>
                              <MenuItem icon={<NotesIcon size={16} color={'navy'} />}>
                                  Notes
                              </MenuItem>
                          </NavLink>
                          <NavLink className={'dropdown-menu__item'} to={"/course/saved"}>
                              <MenuItem icon={<BookmarkIcon size={16} color={'navy'} />}>
                                  Saved
                              </MenuItem>
                          </NavLink>
                          <NavLink className={'dropdown-menu__item'} to={"/resources"}>
                              <MenuItem icon={<ResourcesIcon size={16} color={'navy'} />}>
                                  Resources
                              </MenuItem>
                          </NavLink>
                          <NavLink className={'dropdown-menu__item'} to={"/encouragements"}>
                              <MenuItem icon={<BulbIcon size={16} color={'navy'} />}>
                                  Encouragements
                              </MenuItem>
                          </NavLink>
                          <MenuDivider />
                          <NavLink className={'dropdown-menu__item'} to={"/faq"}>
                              <MenuItem icon={<FaqIcon />}>
                                  FAQs
                              </MenuItem>
                          </NavLink>
                          <MenuItem className={'dropdown-menu__item'} onClick={handleClick} icon={<LogoutIcon />} >
                              Sign Out
                          </MenuItem>
                      </Flex>
                  </DropdownMenu>
              </Menu>
          </Flex>
      </div>
    );
  }