import axios from 'axios'

let tokenPromise;
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.mode = 'cors';
axios.defaults.headers = {
    'Accept': 'application/vnd.api+json',
    'Content-Type': 'application/json',
};

/* Authenticated client. */
const abortController = new AbortController();
const client = axios.create({
    'baseURL': process.env.REACT_APP_API_BASE_URL,
    'mode': 'cors',
    'headers': {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
    },
    'signal': abortController.signal,
});
client.interceptors.request.use( async (config) => {
    try {
        const token = await getToken();
        if (token !== null) {
            config.headers['Authorization'] = `Bearer ${token ? token.access_token : ''}`;
        }
    }
    catch (e) {}

    return config;
});

const getAuthConfig = (config = {}) => {
    const defaultConfig = {
        token_name: 'drupal-oauth-token',
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
        scope: 'student',
        expire_margin: 3000,
    };
    return {...defaultConfig, ...config}
}

const getClient = () => {
    return client;
}

const abort = () => {
    abortController.abort();
}

const login = async (user) => {
    let formData = {
        'grant_type': 'password',
        'client_id': getAuthConfig().client_id,
        'client_secret': getAuthConfig().client_secret,
        'scope': getAuthConfig().scope,
        'username': user.email,
        'password': user.password
    }

    let userData = {...formData, ...user}
    const response = await axios.request({
        url: '/oauth/token',
        method: 'POST',
        data: userData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })


    if (response.data) {
        saveToken(response.data);
    }
    return response.data
}

const oneTimeLogin = async (values) => {

    let formData = {
        'grant_type': 'user_hash',
        'client_id': getAuthConfig().client_id,
        'client_secret': getAuthConfig().client_secret,
        'scope': getAuthConfig().scope,
        'user_id': values.id,
        'timestamp': values.timestamp,
        'hash': values.hash,
    }


    const response = await axios.request({
        url: '/oauth/token',
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })

    if (response.data) {
        saveToken(response.data);
    }
    return response.data
}

const saveToken = (data) => {
    let token = Object.assign({}, data);
    token.date = Math.floor(Date.now() / 1000);
    token.expires_at = token.date + token.expires_in;
    localStorage.setItem(getAuthConfig().token_name, JSON.stringify(token));
    tokenPromise = null;
    return token;
}

const fetchToken = async () => {
    let token = localStorage.getItem(getAuthConfig().token_name) !== null
      ? JSON.parse(localStorage.getItem(getAuthConfig().token_name))
      : false;

    if (!token) {
        console.log('empty token');
        return null;
    }

    const {expires_at, refresh_token} = token;
    if (expires_at - getAuthConfig().expire_margin < parseInt(Date.now() / 1000)) {
        try {
            const tokenData = await submitRefreshToken(refresh_token);
            if (tokenData) {
                token = saveToken(tokenData);
            }
            else {
                token = false;
            }
        }
        catch (e) {
            token = false;
        }
    }

    return token
}

const getToken = async () => {
    if (tokenPromise) {
        return tokenPromise;
    }
    else {
        tokenPromise = new Promise(async (resolve, reject) => {
            try {
                const token = await fetchToken();
                resolve(token);
            }
            catch (e) {
                reject(e);
            }
        });

        return tokenPromise;
    }
}

const submitRefreshToken = async (refresh_token) => {
    console.log("getting refresh token");

    let formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('client_id', getAuthConfig().client_id);
    formData.append('client_secret', getAuthConfig().client_secret);
    formData.append('refresh_token', refresh_token);

    try {
        const response = await axios.request({
            url: '/oauth/token',
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })

        return response.data
    }
    catch (e) {
        return false;
    }
}

const logout = async () => {
    tokenPromise = null;
    localStorage.removeItem('drupal-oauth-token');
}

const requestNewPassword = async (user) => {
    const base = window.location.origin.toString()
    let formData = {
        'mail': user.email,
        'redirect': `${base}/change-password`
    }

    const response = await axios.request({
        url: '/api/v1/reset-password',
        method: 'POST',
        data: formData,
    })
    return response
}

const changePassword = async (values) => {
    let formData = {
        'pass': {
            'value': values.confirmPassword,
            'existing': values.existingPassword
        }
    }
    const response = getClient().request({
        url: '/api/v1/account',
        method: 'PATCH',
        data: formData,
    })
    return response
}


const setPassword = async (values) => {
    const {password, hash, id, timestamp} = values
    let formData = {
        'password': password,
    }
    const response = await axios.request({
        url: `/api/v1/reset-password/${id}/${timestamp}/${hash}`,
        method: 'PATCH',
        data: formData,
    })
    return response
}

const authService = {
    getClient,
    abort,
    login,
    logout,
    requestNewPassword,
    changePassword,
    getToken,
    oneTimeLogin,
    submitRefreshToken,
    setPassword,
}

export default authService