import { redirect } from 'react-router-dom';
import {store} from "app/store";
import {fetchCourseContent, fetchCourseContents, getCourseContent} from "./courseContentSlice";
import wipeService from "../media/wipeService";
import {preloadMedia} from "features/media/mediaSlice";
import mediaService from "features/media/mediaService";
import {toast} from "react-toastify";

export default async function courseContentLoader({ params }) {
  const result = await store.dispatch(fetchCourseContent(params.id));
  const content = result.payload;

  // Redirect locked content to parent segment or module.
  if (content.locked || content.status === 403) {
    if (content.message) {
      toast.error(content.message);
    }

    if (content.segment?.id) {
      return redirect(`/course/content/${content.segment.id}`);
    }
    else if (content.module?.id) {
      return redirect(`/course/content/${content.module.id}`);
    }
    else {
      return redirect('/');
    }
  }

  if (content.type === 'course_activity' && content.content?.type === 'loop' && content.next?.id) {
    return redirect(`/course/content/${content.next.id}`);
  }

  if (Array.isArray(content?.parents)) {
    const parentIds = content.parents.map(parent => parent.id);
    await store.dispatch(fetchCourseContents(parentIds));
  }

  if (['course_segment', 'course_activity'].includes(content.type)) {
    if (content.type === 'course_segment' && content.module?.id) {
      const moduleData = getCourseContent(store.getState(), content.module.id);
      if (moduleData?.wipe_in && moduleData.children.length > 0 && moduleData.children[0].id === content.id) {
        await wipeService.showWipe({...moduleData.wipe_in});
      }
    }

    if (content.wipe_in) {
      await wipeService.showWipe({...content.wipe_in});
    }

    if (content.wipe_out) {
      const media = mediaService.selectMediaByResolution(content.wipe_out.video);

      if (media) {
        store.dispatch(preloadMedia(media)).then(() => {
          // preload video
        });
      }
    }
  }

  // Redirect segment to first incomplete activity.
  if (content.type === 'course_segment') {
    const children = content.children || [];

    // @todo Where course segment with no children should be redirected?
    if (children.length > 0) {
      const last = children.find(activity => {
        const complete = activity.progress?.complete || 0;
        return !activity.locked && complete < 1;
      }) || children[0];
      return redirect(`/course/content/${last.id}`);
    }
  }

  // Redirect Group activity to first child activity within it.
  if (content.type === 'course_activity' && content.content?.type === 'group') {
    const children = content.children || [];
    if (children.length > 0) {
      return redirect(`/course/content/${children[0].id}`);
    }
  }

  return content;
}
