import {useContext, useState} from 'react';
import {
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text, Flex,
} from '@chakra-ui/react';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import RichText from 'components/Activities/RichText';
import {ActivityContext} from "components/course/Activity/ActivityContext";

export default function Accordion({data}) {
  const {onPrev, onNext} = useContext(ActivityContext);
  const {content} = data;
  const items = content?.content?.items || [];
  const [page, setPage] = useState(0);
  const totalPages = items.length;
  const [openItems, setOpenItems] = useState([0]);

  const handlePrev = () => {
    if (page > 0) {
      const newPage = page - 1;
      setPage(newPage);
      setOpenItems([newPage]);
    } else {
      onPrev();
    }
  }

  const handleNext = () => {
    if (page < (totalPages - 1)) {
      const newPage = page + 1;
      setPage(newPage);
      setOpenItems([newPage]);
    } else {
      onNext();
    }
  }

  const handleClick = (index) => {
    if (page < (totalPages - 1)) return;

    if (openItems.includes(index)) {
      setOpenItems(openItems.filter(item => item !== index));
    } else {
      setOpenItems([...openItems, index]);
    }
  }

  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: true,
      pagerType: 'mini',
      totalPages,
      currentPage: page,
      setCurrentPage: setPage,
      canNavigate: () => true,
      onNext: handleNext,
      onPrev: handlePrev,
    }}>
      <ActivityWrapper title={content?.title} description={content?.description}>
        <ChakraAccordion index={openItems} as={Flex} direction="column" gap={2} mt={4}>
          {/* Render next item as hidden for proper animation on next. */}
          {items.slice(0, page + 2).map((item, index) => (
            <AccordionItem key={index} border="none" visibility={index > page ? 'hidden' : 'visible'}>
              <AccordionButton bg="primary.navy" px={4} py={3} onClick={() => handleClick(index)}
                               borderRadius="lg" _expanded={{borderBottomRadius: 0}}>
                <Text color="primary.white" as="h2" flex="1" align="left">{item.title}</Text>
                <AccordionIcon/>
              </AccordionButton>
              <AccordionPanel color="primary.navy" bg="washedOut.orange" px={4} py={3} borderBottomRadius="lg">
                <RichText text={item.description}/>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </ChakraAccordion>
      </ActivityWrapper>
    </ActivityContext.Provider>
  );
}
