import {DotLottiePlayer, PlayerEvents} from '@dotlottie/react-player';
import {checkAutoplay, cn} from "app/helpers";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";

const LottieController = class {
  constructor(lottie) {
    this.lottie = lottie;
  }

  play() {
    return this.lottie.play();
  }

  pause() {
    return this.lottie.pause();
  }

  seek(timestamp = 0) {
    const animationItem = this.lottie?.getAnimationInstance();
    if (animationItem) {
      return this.lottie.seek(timestamp * animationItem.frameRate + 1);
    }
    else {
      return this.lottie.seek(0);
    }
  }

  mute() {
    const audioController = this.lottie?.getAnimationInstance()?.audioController;
    if (audioController) {
      return audioController.mute();
    }
  }

  unmute() {
    const audioController = this.lottie?.getAnimationInstance()?.audioController;
    if (audioController) {
      return audioController.unmute();
    }
  }

  setVolume(volume) {
    const audioController = this.lottie?.getAnimationInstance()?.audioController;
    if (audioController) {
      audioController.setVolume(volume);
    }
  }

  get muted() {
    const audioController = this.lottie?.getAnimationInstance()?.audioController;
    return audioController?._isMuted || false;
  }

  get volume() {
    const audioController = this.lottie?.getAnimationInstance()?.audioController;
    return audioController?.getVolume();
  }

  get paused() {
    return this.lottie?.getState().currentState !== 'playing';
  }

  get ended() {
    return this.lottie?.getState().currentState === 'completed';
  }
}

const LottiePlayer = forwardRef(function LottiePlayer({
                                                        src,
                                                        renderer = 'canvas',
                                                        autoPlay = true,
                                                        muted = false,
                                                        volume = 1,
                                                        loop = false,
                                                        onComplete,
                                                        onCompleteOffset = 0,
                                                        onError,
                                                        restart = true
                                                      }, ref) {
  const classes = cn('lottie-player');
  const [complete, setComplete] = useState(false);
  const [initFrame, setInitFrame] = useState(0);
  const lottieRef = useRef();
  const [lottieController, setLottieController] = useState(null);
  const [ratio, setRatio] = useState();

  const _updateVolume = function () {
    this.audios.forEach(audio => {
      const newVolume = this._volume * (this._isMuted ? 0 : 1);
      if (audio.audio._howl.volume() !== newVolume) {
        audio.audio._howl.fade(audio.audio._howl.volume(), newVolume, 50);
      }
    });
  };

  if (lottieRef.current) {
    const audioController = lottieRef.current.getAnimationInstance()?.audioController;
    if (audioController) {
      // There is a bug in DotLottie player. @todo find better fix
      audioController._updateVolume = _updateVolume.bind(audioController);
    }
  }

  useImperativeHandle(ref, () => {
    return lottieController;
  }, [lottieController]);

  useEffect(() => {
    const lottie = lottieRef.current;
    const lottieController = !lottie ? null : new LottieController(lottie);
    setLottieController(lottieController);
  }, [lottieRef.current]);

  const handleComplete = () => {
    onCompleteOffset === 0 && setComplete(true);
  };

  const handleEnterFrame = () => {
    const animationItem = lottieRef.current?.getAnimationInstance();
    if (animationItem) {
      const currentTime = animationItem.currentFrame / animationItem.frameRate * 1000;
      const totalTime = animationItem.totalFrames / animationItem.frameRate * 1000;

      if (!complete && onCompleteOffset > 0 && totalTime - currentTime < onCompleteOffset) {
        setComplete(true);
      }
    }
  };

  const handleEvents = (e, data) => {
    const lottie = lottieRef.current;
    const animationItem = lottie?.getAnimationInstance();
    const audioController = animationItem?.audioController;
    const animationData = animationItem?.animationData;

    if (lottie) {
      switch (e) {
        case PlayerEvents.Ready:
          if (animationData?.w && animationData?.h) {
            setRatio(animationData.w / animationData.h);
          }

          if (audioController) {
            // There is a bug in DotLottie player. @todo find better fix
            audioController._updateVolume = _updateVolume.bind(audioController);
          }

          audioController._isMuted = muted;
          audioController.setVolume(volume);
          audioController.audios.forEach(audio => {
            const sound = audio.audio._howl;
            sound.on('play', () => {
              // Sync audio with animation.
              const initTime = ((animationItem.currentFrame - (audio?.data?.st || 0)) + 1) / animationItem.frameRate;
              sound.seek(initTime > 0 ? initTime : 0);
            });
          });

          if (autoPlay) {
            if (!muted) {
              checkAutoplay().then(() => {
                // Autoplay with sound is allowed.
                lottie.getState().currentState !== 'loading' && lottie.play();
              }).catch(() => {
                // Autoplay with sound is not allowed.
                audioController._isMuted = true;
                lottie.play();
              });
            } else {
              lottie.play();
            }
          }
          break;
        case PlayerEvents.Frame:
          handleEnterFrame();
          break;
        case PlayerEvents.Complete:
          setInitFrame(0);
          handleComplete();
          break;
        case PlayerEvents.Play:
          if (animationItem) {
            const initFrame = lottie.initFrame || 0;
            lottie.seek(initFrame);
          }
          break;
        case PlayerEvents.Freeze:
        case PlayerEvents.Pause:
          setInitFrame(animationItem?.currentFrame || 0);
          break;
      }
    }
  }

  useEffect(() => {
    const lottie = lottieRef.current;
    if (lottie) {
      if (!restart) {
        setInitFrame(lottie.getAnimationInstance()?.currentFrame || 0);
      } else {
        setInitFrame(0);
      }
    }
  }, [src]);

  useEffect(() => {
    const lottie = lottieRef.current;
    if (lottie) {
      lottie.initFrame = initFrame;

      if (lottie.getState().currentState === 'ready' && autoPlay) {
        lottie.play();
      }
      else if (lottie.getState().currentState === 'playing' && !autoPlay) {
        lottie.pause();
      }
    }
  }, [lottieRef.current, initFrame, autoPlay]);

  useEffect(() => {
    if (lottieController) {
      if (!muted) {
        lottieController.setVolume(volume);
        lottieController.unmute();
      }
      else {
        lottieController.mute();
      }
    }
  }, [lottieController, muted, volume]);

  useEffect(() => {
    complete && onComplete && onComplete();
  }, [complete, onComplete]);

  useEffect(() => {
    const lottie = lottieRef.current;

    if (lottie && ratio && lottie.getState().currentState !== 'loading') {
      // Resize sometimes fail with "TypeError: this.audio.volume is not a function" error.
      // It maybe depends on played file.
      // @todo Investigate the issue cause.
      try {
        lottie.resize();
      }
      catch (e) {
        // Do nothing.
      }
    }
  }, [ratio]);

  return src && <DotLottiePlayer className={classes()}
                                 style={{
                                   aspectRatio: ratio || 'initial',
                                 }}
                                 lottieRef={lottieRef}
                                 src={src}
                                 loop={loop}
                                 autoplay={false}
                                 onEvent={handleEvents}
                                 renderer={renderer}
                                 rendererSettings={{
                                   preserveAspectRatio: "xMidYMid slice",
                                 }}
  />
});
export default LottiePlayer;
