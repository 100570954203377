import {cn, isFullscreen, setFullscreen} from "../../app/helpers";
import {useEffect, useState} from "react";

export default function CircleWipeFullscreen({children, isOpen = false, classNames = []}) {
  const classes = cn('animated-container');
  const [animation, setAnimation] = useState('idle');
  const [open, setOpen] = useState(isFullscreen);

  const setOpenPersist = (open) => {
    setFullscreen(open);
    setOpen(open);
  }

  useEffect(() => {
    if (!isOpen === open) {
      if (isOpen) {
        setAnimation('opening');
        setOpenPersist(true);
      }
      else {
        if (document.fullscreenEnabled) {
          document.exitFullscreen().then(() => {
            setAnimation('closing');
          }).catch(() => {
            setAnimation('closing');
          });
        }
        else {
          setAnimation('closing');
        }
      }
    }
  }, [isOpen]);

  return <div className={classes({
    'circle-wipe-fullscreen': true,
    animation,
    open,
  })} onAnimationEnd={() => {
    switch (animation) {
      case 'opening':
        setAnimation('idle');
        document.documentElement.requestFullscreen({navigationUI: 'hide'}).catch((e) => {
          console.error(e.message);
        });
        break;
      case 'fadein':
        setAnimation('idle');
        break;
      case 'closing':
        setAnimation('fadein');
        setOpenPersist(false);
        break;
    }
  }}>
    <div className={classes('scroll')}>
      <div className={classes('content', classNames)}>
        {children}
      </div>
    </div>
  </div>
}
