import { extendTheme } from '@chakra-ui/react'
import { containerStyles } from './containerStyles'
import { cardTheme } from './cardTheme'
import { modalTheme } from './modalTheme'
import { switchTheme } from "./switchTheme";
import { progressTheme } from "./progressTheme";
import { tooltipTheme } from './tooltipTheme';

export const colors = {
  'orange': '#FE5612',
  'orangeWashed': '#FFEEE7',
  'navy': '#0C1851',
  'purple': '#403171',
  'tangerine': '#FF9165',
  'success': '#1AC573',
  'red': '#E55E28',
};

export const theme = extendTheme({
   breakpoints: {
        sm: '20em',
        md: '48em',
        lg: '62.5em',
        xl: '88.75em'
   },
    styles: {
        global: {
            html: {
                scrollBehavior: 'smooth'
            },
            body: {
                backgroundColor: '#F9F9F9'
            }
        }
    },
    fonts: {
        body: "'Red Hat Text', sans-serif",
        heading: "Lora, serif",
        mono: "Menlo, monospace",
    },

    colors: {
        primary: {
            'orange': '#FE5612',
            'navy': '#0C1851',
            'purple': '#403171'
        },
        secondary: {
            'darkblue': '#5A5F86',
            'red': '#E55E28',
            'tangerine': '#FF9165'
        },
        washedOut: {
            'blue': '#D9D6E3',
            'orange': '#FFEEE7',
            'tangerine': '#FFDED1'
        },
        gray: {
            'charcoal': '#2A2A2A'
        },
        lightGrey: {
            100: '#F9F9F9',
            200: '#F0F0F0',
            300: '#E2E2E2'
        },
        other: {
            'hyperlink': '#2F60D8',
            'quote': '#F8E9D5',
            'success': '#1AC573',
            'notice': '#FFBDBD',
            'overlay': 'rgba(0, 0, 0, 0.5)',
            'backHover': '#3D4674',
        }
    },
    components: {
        Button: {
            radii: {
                'rounded': '24px',
            },
            variants: {
              solid: {
                fontFamily: 'Lora, serif',
                borderRadius: '24px',
                paddingLeft: '24px',
                paddingRight: '24px',
                _hover: {
                  bg: '#FF9165'
                },
                fontSize: '14px',
                lineHeight: '24px',
              },
              transparent: {
                fontFamily: 'Lora, serif',
                fontSize: '14px',
                lineHeight: '24px',
                padding: 0,
                borderRadius: 0,
                minWidth: 0,
                _hover: {
                  bg: 'transparent',
                  _disabled: {
                    bg: 'transparent',
                  },
                }
              },
              solidOrange: {
                fontFamily: 'Lora, serif',
                color: 'white',
                bg: 'primary.orange',
                borderRadius: '24px',
                padding: '8px 24px',
                fontSize: '14px',
                lineHeight: '24px',
                path: {
                  fill: 'white',
                },
                _hover: {
                  bg: 'secondary.tangerine',
                  _disabled: {
                    bg: 'secondary.tangerine',
                  },
                },
              },
              solidNavy: {
                fontFamily: 'Lora, serif',
                color: 'white',
                bg: 'primary.navy',
                borderRadius: '24px',
                padding: '8px 24px',
                fontSize: '14px',
                lineHeight: '24px',
                path: {
                  fill: 'white',
                },
                _hover: {
                  bg: 'secondary.darkblue',
                  _disabled: {
                    bg: 'secondary.darkblue',
                  },
                },
              },
              outlineNavy: {
                fontFamily: 'Lora, serif',
                color: 'primary.navy',
                borderColor: 'primary.navy',
                borderWidth: '1px',
                borderRadius: '24px',
                paddingLeft: '24px',
                paddingRight: '24px',
                fontSize: '14px',
                lineHeight: '24px',
                path: {
                  fill: 'primary.navy',
                  transition: 'all .2s',
                },
                _hover: {
                  color: 'white',
                  bg: 'primary.navy',
                  path: {
                    fill: 'white',
                  },
                  _disabled: {
                    bg: 'primary.navy',
                  },
                },
              },
            },
        },
        Container: containerStyles,
        Card: cardTheme,
        Modal: modalTheme,
        Switch: switchTheme,
        Progress: progressTheme,
        Tooltip: tooltipTheme,
    }
});
