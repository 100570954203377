import React from "react";

function Courses({color}) {
    const {isActive} = color
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill={isActive? "#FE5612" : "#0C1851"}
      viewBox="0 0 24 24"
      color={isActive? "#FE5612" : "#0C1851"}
    >
      <mask
        id="mask0_24_1157"
        style={{ maskType: "alpha" }}
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill={color} d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_24_1157)">
        <path
          fill={isActive? "fill-gray-900" : "fill-gray-300"}
          d="M8.875 20.5H5c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 013.5 19v-3.875a2.963 2.963 0 001.763-.85A2.394 2.394 0 006 12.5c0-.7-.246-1.292-.737-1.775a2.963 2.963 0 00-1.763-.85V6c0-.417.146-.77.438-1.062A1.444 1.444 0 015 4.5h4a2.477 2.477 0 01.825-1.575A2.43 2.43 0 0111.5 2.3c.65 0 1.208.208 1.675.625.467.417.742.942.825 1.575h4c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v4a2.477 2.477 0 011.575.825A2.43 2.43 0 0121.7 12.5a2.43 2.43 0 01-.625 1.675A2.477 2.477 0 0119.5 15v4c0 .417-.146.77-.438 1.062A1.444 1.444 0 0118 20.5h-3.875c-.083-.717-.375-1.313-.875-1.788-.5-.475-1.083-.712-1.75-.712s-1.25.237-1.75.712-.792 1.071-.875 1.788zM5 19h2.7c.35-.883.896-1.521 1.638-1.913.741-.391 1.462-.587 2.162-.587s1.421.196 2.163.587c.741.392 1.287 1.03 1.637 1.913H18v-5.45h1.225c.317-.033.558-.154.725-.362.167-.209.25-.438.25-.688 0-.25-.083-.48-.25-.688-.167-.208-.408-.329-.725-.362H18V6h-5.45V4.775c-.033-.317-.154-.558-.362-.725a1.078 1.078 0 00-.688-.25c-.25 0-.48.083-.688.25-.208.167-.329.408-.362.725V6H5v2.75c.767.3 1.375.792 1.825 1.475.45.683.675 1.442.675 2.275 0 .817-.225 1.567-.675 2.25A3.932 3.932 0 015 16.25V19z"
        ></path>
      </g>
    </svg>
  );
}

export default Courses;