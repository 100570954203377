import {
  ButtonGroup,
  Flex, Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay, Text,
} from '@chakra-ui/react';
import CtaButton from '../../buttons/CtaButton';
import OutlineButton from '../../buttons/OutlineButton';
import CompleteIcon from "icons/CompleteIcon";

// @todo Make it usable for other cases. I.e. heading color and icon should be configurable.
export default function ModuleCompleteModal({
  title,
  isOpen,
  onSubmit,
  onClose,
  children,
  submitLabel = 'Continue',
  closeLabel = 'Close',
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" autoFocus={false}>
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalHeader>
          <Flex gap={2} alignItems={'center'}>
            {/* @todo Icon is supposed to be responsive and smaller on mobile. */}
            <CompleteIcon color={'success'} size={25} />
            <Text fontSize={{base: 'lg', lg: '2xl'}} color={'other.success'} as='h2'>{title}</Text>
          </Flex>
        </ModalHeader>

        <ModalBody color={'primary.navy'}>
          {children}
        </ModalBody>

        <ModalFooter justifyContent='start'>
          <ButtonGroup spacing="0" flexWrap="wrap" gap={4} width="100%">
            {onSubmit &&
              <CtaButton width={{base: '100%', md: 'auto'}} txColor={'white'} copy={submitLabel} bgColor={'primary.orange'} onClick={onSubmit} />
            }
            <OutlineButton width={{base: '100%', md: 'auto'}} _hover={{color: 'white', bg: 'primary.navy'}} copy={closeLabel} variant={'outline'} txColor={'primary.navy'} onClick={onClose} />
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
