import AnimationPlayer from "components/media/AnimationPlayer";
import {cn} from "app/helpers";
import {useCallback, useState} from "react";
import ActivityWrapper from "components/course/Activity/ActivityWrapper";
import mediaService from "features/media/mediaService";

let delay;
export default function Animation({data}) {
  const classes = cn('animation-activity');
  const {animation, muted} = data?.content?.content;
  const [active, setActive] = useState(false);
  const autoPlay = mediaService.allowAutoplay.value;

  const handleComplete = useCallback(() => {
    setActive(true);
  }, []);

  const handleFocus = () => {
    clearTimeout(delay);
    setActive(true);

    delay = setTimeout(() => {
      setActive(false);
    }, 2000);
  };

  const handleBlur = (e) => {
    clearTimeout(delay);

    delay = setTimeout(() => {
      setActive(false);
    }, 2000);
  }

  return <div className={classes({
    active,
  })} onFocus={handleFocus} onBlur={handleBlur} onMouseEnter={handleFocus} onMouseLeave={handleBlur} onMouseMove={handleFocus} onClick={handleFocus}>
    <ActivityWrapper>
      <AnimationPlayer files={animation} loop={false} autoPlay={autoPlay} muted={muted || false} onComplete={handleComplete}/>
    </ActivityWrapper>
  </div>
}
