import {useCallback, useContext, useState} from 'react';
import { Box } from '@chakra-ui/react';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import {ActivityContext} from "components/course/Activity/ActivityContext";

export default function EditableTable({data}) {
  const {onNext} = useContext(ActivityContext);
  const { content } = data;
  const columnNames = content?.content?.column_names || [];
  const rowsNumber = content?.content?.rows_number || 1;

  const savedValues = data.answer?.value || [];
  // @todo Use useMemo() instead?
  const getDefaultValues = useCallback(() => {
    const defaultValues = {};

    columnNames.forEach((name, colIndex) => {
      const savedColumn = savedValues.find(item => item.column === name);
      if (!savedColumn) {
        return;
      }

      const savedAnswers = savedColumn.values || [];
      if (!savedAnswers.length) {
        return;
      }

      savedAnswers.slice(0, rowsNumber).forEach((_, rowIndex) => {
        if (savedAnswers[rowIndex]) {
          defaultValues[`${colIndex}_${rowIndex}`] = savedAnswers[rowIndex].trim();
        }
      });
    });

    return defaultValues;
  }, [data]);

  const defaultValues = getDefaultValues();
  const [values, setValues] = useState(defaultValues);

  const handleInput = (colIndex, rowIndex, value) => {
    const key = `${colIndex}_${rowIndex}`;
    setValues(prevValues => ({...prevValues, [key]: value}));
  };

  const answers = columnNames.map((name, colIndex) => {
    return {
      column: name,
      values: Array.from({length: rowsNumber}).map((_, rowIndex) => values[`${colIndex}_${rowIndex}`] || ''),
    };
  });

  const isValid = answers.every(column => column.values.every(value => value !== ''));

  const handleSubmit = () => {
    onNext(answers);
  };

  // @todo Avoid the need to wrap tables into .parse-html to apply styles.
  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: isValid,
      onNext: handleSubmit,
    }}>
      <ActivityWrapper title={content?.title} description={content?.description}>
        <Box className='parse-html' mt={8}>
          <table>
            <thead>
            <tr>
              {columnNames.map((name, index) => {
                return <th key={index}>{name}</th>;
              })}
            </tr>
            </thead>
            <tbody>
            {Array.from({length: rowsNumber}).map((_, rowIndex) => (
              <tr key={rowIndex}>
                {columnNames.map((name, colIndex) => {
                  return (
                    <td
                      key={colIndex}
                      onInput={event => handleInput(colIndex, rowIndex, event.target.textContent.trim())}
                      className={!values[`${colIndex}_${rowIndex}`] ? 'no-value' : ''}
                      contentEditable={true}
                      suppressContentEditableWarning={true}
                    >
                      {/* Cell content shouldn't change between re-renders, that's why useCallback() hook is used. */}
                      {/* Truly controlled content will cause the cursor stay at the start on input. */}
                      {defaultValues[`${colIndex}_${rowIndex}`] || ''}
                    </td>
                  );
                })}
              </tr>
            ))}
            </tbody>
          </table>
        </Box>
      </ActivityWrapper>
    </ActivityContext.Provider>
  );
}
