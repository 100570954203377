import {
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box
  } from '@chakra-ui/react'
import parse from 'html-react-parser';


const Faq = ({data}) => {

    const { question, answer  } = data
   
    
    return (
        <AccordionItem>
            <h2>
            <AccordionButton>
                <Box as="span" flex='1' textAlign='left' my={4} color={'primary.navy'}>
                    {question}
                </Box>
                <AccordionIcon color={'primary.navy'} borderColor={'primary.navy'} />
            </AccordionButton>
            </h2>
            <AccordionPanel color={'secondary.darkblue'} pb={4}>
                <Box css={{
                    p: { margin: "16px 0" },
                 }}>
                    {parse(answer)}
              </Box>
            </AccordionPanel>
        </AccordionItem>
    )
}

export default Faq

