import { Flex, Image } from '@chakra-ui/react';
import {useContext} from "react";
import VolumeIcon from "../../icons/VolumeIcon";
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import {ActivityContext} from "components/course/Activity/ActivityContext";

export default function Slide({data}) {
    const {voiceover} = useContext(ActivityContext);
    const image = data?.content?.content?.image?.file;
    const isEmpty = (!data?.content?.description || typeof data?.content?.description !== 'string' || data?.content?.description.length === 0) && !data?.content?.background;
    const aside = image ? <Image src={image.url} alt={image.alt} /> : null;

    return(
      <ActivityWrapper title={data?.content?.title} description={data?.content?.description} aside={aside}>
          {isEmpty && voiceover && <Flex flexFlow={'column'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
              <VolumeIcon size={144} />
          </Flex>}
      </ActivityWrapper>
    )
}
