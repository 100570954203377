import {useEffect, useState} from "react";
import VolumeIcon from "../../../icons/VolumeIcon";
import MutedIcon from "../../../icons/MutedIcon";
import {cn} from "../../../app/helpers";
import {useDispatch} from "react-redux";
import {setMediaMuted, setMediaVolume} from "../../../features/media/mediaSlice";

let timeout;
export default function Volume({media, isMuted = false, volume = 100}) {
  const dispatch = useDispatch();
  const classes = cn('player-controls');
  const steps = 100;
  const [isVisible, setVisible] = useState(false);
  const [overflow, setOverflow] = useState('hidden');

  const handleClick = (e) => {
    e.preventDefault();

    if (media) {
      media.muted = media.volume === 0 || !isMuted;
      dispatch(setMediaMuted(media.muted));
    }
  };

  const handleChange = (event) => {
    const volume = event.target.value / steps;
    media.muted = volume === 0;
    media.volume = volume;
    dispatch(setMediaVolume(media.volume));
    dispatch(setMediaMuted(media.muted));
  }

  const makeColor = (color, fract) => {
    return color + ' ' + (fract * 100) + '%';
  }

  const gradient = [
    'to right',
    makeColor('#FFFFFF', 0),
    makeColor('#FFFFFF', isMuted ? 0 : volume),
    makeColor('#E2E2E2', isMuted ? 0 : volume),
    makeColor('#E2E2E2', 1),
  ];

  useEffect(() => {
    if (media) {
      media.volume = volume;
      media.muted = isMuted;
    }
  }, [media]);

  useEffect(() => {
    if (isVisible) {
      setOverflow('visible');
    } else {
      timeout = setTimeout(() => {
        setOverflow('hidden');
      }, 400);
    }
  }, [isVisible]);

  return <div className={classes('volume')}
              style={{
                overflow: overflow
              }}
              onMouseOver={() => {
                clearTimeout(timeout);
                setVisible(true);
              }}
              onMouseLeave={() => {
                isVisible && (timeout = setTimeout(() => {
                  setVisible(false);
                }, 400));
              }}>
    {/* @todo create separate component for range elements */}
    <div className={cn('range-container')({vertical: true})}>
      <input type="range" min={0} max={100} value={isMuted ? 0 : volume * steps} onChange={handleChange}
             style={{
               background: 'linear-gradient(' + gradient.join(',') + ')',
               opacity: isVisible ? 1 : 0,
             }}/>
    </div>
    <button className={classes('button')}
            onClick={handleClick}>{isMuted ? <MutedIcon/> : <VolumeIcon/>}</button>
  </div>
}
