import {cn} from "app/helpers";
import ProgressBar from "./ProgressBar";
import {useEffect, useState} from "react";
import PlayButton from "./PlayButton";
import Volume from "./Volume";
import {Box, Switch, useBreakpointValue} from "@chakra-ui/react";
import TimeDuration from "./TimeDuration";
import FullscreenButton from "./FullscreenButton";
import SettingsButton from "./SettingsButton";
import CaptionsIcon from "../../../icons/CaptionsIcon";
import AutoplayIcon from "../../../icons/AutoplayIcon";
import "../../../styles/components/media/_controls.scss";
import CaptionsButton from "./CaptionsButton";
import {useDispatch, useSelector} from "react-redux";
import {
  getActiveMedia,
  getMediaUserSettings,
  enableMediaAutoplay,
  enableMediaCaptions,
  setActiveMedia, setMediaPlaying
} from "features/media/mediaSlice";

const SettingSwitch = ({id, title, icon, value, setValue}) => {
  const classes = cn('player-controls');
  const variant = {};
  variant[id] = true;

  const handleChange = (e) => {
    setValue && setValue(e.target.checked);
  };

  return <div className={classes('setting', variant)}>
    <label className={classes('setting-label')}>
      {icon}
      <span>{title}</span>
      <Switch size="sm" variant="outline" isChecked={value} onChange={handleChange} />
    </label>
  </div>
}

export default function Controls({media, allowAutoPlay = true, allowCaptions = true, allowFullscreen, toggleFullscreen, classNames = [], captionsButton = 'default'}) {
  const dispatch = useDispatch();
  const classes = cn('player-controls');
  const showCaptionsButton = useBreakpointValue({
    base: false,
    lg: true,
  }) && (captionsButton === 'default' || captionsButton);
  const [showSettings, setShowSettings] = useState(false);
  const userSettings = useSelector(state => getMediaUserSettings(state));
  const hasSettings = allowAutoPlay || (allowCaptions && !showCaptionsButton);
  const activeMedia = useSelector(getActiveMedia);

  const [state, setState] = useState({
    isPlaying: media ? (media.autoplay || !media.paused) : false,
    isEnded: false,
    isMuted: userSettings.muted,
    currentTime: 0,
    duration: 0,
    volume: userSettings.volume,
  });

  const handleState = (event) => {
    const media = event.target;

    setState({
      ...state,
      isPlaying: !media.paused,
      isEnded: media.ended,
      isMuted: media.muted,
      currentTime: media.currentTime,
      duration: !isNaN(media.duration) ? media.duration : 0,
      volume: media.volume,
    });
  };

  const handlePlay = (event) => {
    const media = event.target;

    if (!media.paused) {
      dispatch(setActiveMedia(media));
    }
    handleState(event);
  };

  useEffect(() => {
    if (media) {
      media.addEventListener('timeupdate', handleState);
      media.addEventListener('durationchange', handleState);
      media.addEventListener('play', handlePlay);
      media.addEventListener('pause', handleState);
      media.addEventListener('volumechange', handleState);
    }

    return () => {
      if (media) {
        media.removeEventListener('timeupdate', handleState);
        media.removeEventListener('durationchange', handleState);
        media.removeEventListener('play', handlePlay);
        media.removeEventListener('pause', handleState);
        media.removeEventListener('volumechange', handleState);
      }
    }
  }, [media]);

  useEffect(() => {
    if (media === activeMedia) {
      const isPlaying = media ? !media.paused : state.isPlaying;
      dispatch(setMediaPlaying(isPlaying));
    }
  }, [media, activeMedia, state.isPlaying]);

  const isFullScreenEnabled = () => {
    if (document.fullscreenEnabled) {
      return !!document.fullscreenElement;
    }

    if (media && media.webkitSupportsFullscreen) {
      return media.webkitDisplayingFullscreen;
    }
    return false;
  };

  const enterFullScreen = async () => {
    try {
      if (document.fullscreenEnabled) {
        if (document.pictureInPictureElement) {
          await document.exitPictureInPicture();
        }
        const fullScreenElement = this.config_.fullScreenElement;
        await fullScreenElement.requestFullscreen({navigationUI: 'hide'});
      } else {
        if (media && media.webkitSupportsFullscreen) {
          media.webkitEnterFullscreen();
        }
      }
    } catch (e) {}
  }

  const exitFullScreen = async () => {
    if (document.fullscreenEnabled) {
      await document.exitFullscreen();
    } else {
      if (media && media.webkitSupportsFullscreen) {
        media.webkitExitFullscreen();
      }
    }
  }

  toggleFullscreen = typeof toggleFullscreen === 'function' ? toggleFullscreen : async () => {
    if (isFullScreenEnabled()) {
      await exitFullScreen();
    } else {
      await enterFullScreen();
    }
  };

  const enableCaptions = (enabled) => {
    dispatch(enableMediaCaptions(enabled));
  }

  const enableAutoplay = (enabled) => {
    dispatch(enableMediaAutoplay(enabled));
  }

  return <Box className={classes({}, classNames)}>
    {hasSettings && <Box className={classes('settings', {hidden: !showSettings})}>
      {(allowCaptions && !showCaptionsButton) && <SettingSwitch key={'captions'} id={'captions'} title={'Captions'} icon={<CaptionsIcon size={16}  />} value={userSettings.captions} setValue={enableCaptions}/>}
      {allowAutoPlay && <SettingSwitch key={'autoplay'} id={'autoplay'} title={'Autoplay'} icon={<AutoplayIcon/>} value={userSettings.autoPlay} setValue={enableAutoplay}/>}
    </Box>}
    <ProgressBar media={media} currentTime={state.currentTime} duration={state.duration} />
    <Box className={classes('buttons')}>
      <PlayButton media={media} isPlaying={state.isPlaying} isEnded={state.isEnded} />
      <TimeDuration currentTime={state.currentTime} duration={state.duration}/>
      <Box className={classes('separator')}/>
      <Volume media={media} isMuted={state.isMuted} volume={state.volume} />
      {(allowCaptions && showCaptionsButton) && <CaptionsButton enabled={userSettings.captions} setCaptions={enableCaptions}/>}
      {hasSettings && <SettingsButton {...{showSettings, setShowSettings}}/>}
      {allowFullscreen && <FullscreenButton {...{toggleFullscreen}}/>}
    </Box>
  </Box>
}
