import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {handleApiError} from "../auth/authSlice";
import mediaService from "./mediaService";

const initialState = {
  userSettings: {
    autoPlay: localStorage.getItem('autoPlay') === null ? true : JSON.parse(localStorage.getItem('autoPlay')),
    captions: localStorage.getItem('captions') === null ? false : JSON.parse(localStorage.getItem('captions')),
    volume: localStorage.getItem('volume') === null ? 1 : JSON.parse(localStorage.getItem('volume')),
    muted: localStorage.getItem('muted') === null ? false : JSON.parse(localStorage.getItem('muted')),
  },
  isPlaying: false,
  activeMedia: null,
  globalMedia: null,
  globalMediaSettings: {},
  loadedMedia: {},
};

export const getLoadedMedia = (state, mid) => {
  return (mid && state.media.loadedMedia[mid]) || {
    isLoading: false,
    isLoaded: false,
  };
};

export const getActiveMedia = state => {
  return state.media.activeMedia;
}

export const getMediaPlaying = state => {
  return state.media.isPlaying;
}

export const getMediaUserSettings = state => {
  return state.media.userSettings;
}

export const getGlobalMedia = state => {
  return state.media.globalMedia;
}

export const getGlobalMediaSettings = state => {
  return state.media.globalMediaSettings;
}

export const preloadMedia = createAsyncThunk('media/preloadMedia', async (media, thunkAPI) => {
  try {
    const loaded = getLoadedMedia(thunkAPI.getState(), media.mid);
    if (loaded?.isLoaded && loaded?.src) {
      return loaded.src;
    }

    return await mediaService.preloadMedia(media);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    enableMediaAutoplay: (state, action) => {
      const enabled = action.payload;
      localStorage.setItem('autoPlay', JSON.stringify(enabled));
      state.userSettings.autoPlay = enabled;
    },
    enableMediaCaptions: (state, action) => {
      const enabled = action.payload;
      localStorage.setItem('captions', JSON.stringify(enabled));
      state.userSettings.captions = enabled;
    },
    setMediaVolume: (state, action) => {
      const volume = action.payload;
      localStorage.setItem('volume', JSON.stringify(volume));
      state.userSettings.volume = volume;
    },
    setMediaMuted: (state, action) => {
      const muted = action.payload;
      localStorage.setItem('muted', JSON.stringify(muted));
      state.userSettings.muted = muted;
    },
    setActiveMedia: (state, action) => {
      const media = action.payload;
      if (state.activeMedia && state.activeMedia !== media) {
        try {
          state.activeMedia.pause();
        }
        catch (e) {}
      }
      state.activeMedia = media;
      if (media) {
        state.isPlaying = !media.paused;
      }
      else {
        state.isPlaying = false;
      }
    },
    setMediaPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(preloadMedia.pending, (state, action) => {
        const media = action.meta.arg;
        if (media.mid) {
          const item = state.loadedMedia[media.mid] || {
            isLoaded: false,
          };
          state.loadedMedia[media.mid] = {
            ...item,
            isLoading: true,
          };
        }
      })
      .addCase(preloadMedia.fulfilled, (state, action) => {
        const media = action.meta.arg;
        if (media.mid) {
          state.loadedMedia[media.mid] = {
            ...state.loadedMedia[media.mid],
            isLoading: false,
            isLoaded: true,
            src: action.payload,
          };
        }
      })
      .addCase(preloadMedia.rejected, (state, action) => {
        const media = action.meta.arg;
        if (media.mid) {
          state.loadedMedia[media.mid] = {
            ...state.loadedMedia[media.mid],
            isLoading: false,
            isError: true,
          };
        }
      });
  },
});

export const { enableMediaAutoplay, enableMediaCaptions, setActiveMedia, setMediaPlaying, setMediaVolume, setMediaMuted } = mediaSlice.actions
export default mediaSlice.reducer;
