import {cn} from "../../../app/helpers";
import PlayIcon from "../../../icons/PlayIcon";
import PauseIcon from "../../../icons/PauseIcon";
import ReplayIcon from "../../../icons/ReplayIcon";

export default function PlayButton({media, isPlaying = false, isEnded = false}) {
  const classes = cn('player-controls');
  const handleClick = (e) => {
    e.preventDefault();

    if (media) {
      isPlaying ? media.pause() : media.play();
    }
  }

  return <button className={classes('button', {'play': true})}
                 onClick={handleClick}>{isPlaying ? <PauseIcon/> : (isEnded ? <ReplayIcon/> : <PlayIcon/>)}</button>
}
