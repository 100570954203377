import {checkAutoplay, cn} from "app/helpers";
import {useEffect, useRef, useState} from "react";
import mediaService from "../../features/media/mediaService";
import {useDispatch, useSelector} from "react-redux";
import {
  getActiveMedia,
  getLoadedMedia,
  getMediaPlaying,
  getMediaUserSettings,
  preloadMedia
} from "features/media/mediaSlice";
import {useSize} from "@chakra-ui/react-use-size"
import MutedIcon from "icons/MutedIcon";
import LottiePlayer from "components/media/LottiePlayer";
import AnimationVideoPlayer from "components/media/AnimationVideoPlayer";

export default function AnimationPlayer({
                                          files = [],
                                          loop = true,
                                          autoPlay = true,
                                          onError,
                                          onComplete,
                                          muted = true,
                                          onCompleteOffset = 0
                                        }) {
  const dispatch = useDispatch();
  const classes = cn('animation-player');
  const elementRef = useRef();
  const mediaRef = useRef();
  const dimensions = useSize(elementRef);

  const activeMedia = useSelector(getActiveMedia);
  const isMediaPlaying = useSelector(getMediaPlaying);
  const userSettings = useSelector(getMediaUserSettings);

  const [selected, setSelected] = useState();
  const loadedMedia = useSelector(state => getLoadedMedia(state, selected?.mid));
  const [isMuted, setMuted] = useState(muted || userSettings.muted);
  const volume = isMediaPlaying && activeMedia && !activeMedia.paused ? 0 : userSettings.volume;

  useEffect(() => {
    checkAutoplay().catch(() => {
      setMuted(true);
    });
  }, []);

  useEffect(() => {
    if (!muted) {
      setMuted(userSettings.muted);
    }
  }, [userSettings.muted]);

  useEffect(() => {
    if (elementRef.current && dimensions) {
      const media = mediaService.selectMediaByResolution(files, dimensions.width, dimensions.height);
      if (!selected || selected.mid !== media.mid) {
        setSelected(media);
      }
    }
  }, [dimensions, files]);

  useEffect(() => {
    if (!loadedMedia.isLoaded && !loadedMedia.isLoading && selected) {
      dispatch(preloadMedia(selected)).then(action => {
        if (action.type === 'media/preloadMedia/fulfilled' && !action.payload) {
          onError && onError();
        }
      });
    }
  }, [loadedMedia, selected]);

  const handleClick = () => {
    const media = mediaRef?.current;

    if (media && media.paused && typeof media.play === 'function') {
      media.play();
    }
    else {
      if (!muted) {
        setMuted(!isMuted);
      }
    }
  };

  return <div className={classes()}
              ref={elementRef}
              onClick={handleClick}>
    {selected?.type === 'video' &&
    <AnimationVideoPlayer ref={mediaRef}
                          src={loadedMedia.src}
                          width={selected?.file?.width} height={selected?.file?.height}
                          autoPlay={autoPlay} muted={isMuted} volume={volume} loop={loop}
                          onError={onError} onComplete={onComplete} onCompleteOffset={onCompleteOffset} restart={false}/>
    }
    {selected?.type === 'lottie' &&
    <LottiePlayer ref={mediaRef}
                  src={loadedMedia.src}
                  renderer={selected?.renderer || 'canvas'}
                  autoPlay={autoPlay} muted={isMuted} volume={volume} loop={loop}
                  onError={onError} onComplete={onComplete} onCompleteOffset={onCompleteOffset} restart={false}/>
    }
    {!muted && isMuted && <div className={classes('muted')}>
      <MutedIcon size={40}/>
    </div>}
  </div>
}
