import {useContext, useEffect, useState} from "react";
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom';
import {Box, Text} from "@chakra-ui/react";
import {cn, isFullscreen} from "app/helpers";
import OpenQuestion from "components/Activities/OpenQuestion";
import Options from "components/Activities/Options";
import WordCloud from "components/Activities/WordCloud";
import Slide from "components/Activities/Slide";
import Quote from "components/Activities/Quote";
import GroupedOptions from "components/Activities/GroupedOptions";
import DateActivity from 'components/Activities/DateActivity';
import Scale from 'components/Activities/Scale';
import Video from "components/Activities/Video";
import {toast} from "react-toastify";
import Tips from "components/tips/Tips";
import Hotspots from 'components/Activities/Hotspots';
import Flow from 'components/Activities/Flow';
import Steps from "components/Activities/Steps";
import SelectWord from 'components/Activities/SelectWord';
import "styles/components/course/_activity.scss";
import {VoiceoverContext} from "components/media/VoiceoverContext";
import DragToReorder from 'components/Activities/Drag/DragToReorder';
import DragToSelect from 'components/Activities/Drag/DragToSelect';
import DragToSequence from 'components/Activities/Drag/DragToSequence';
import Accordion from 'components/Activities/Accordion';
import EditableTable from 'components/Activities/EditableTable';
import CircleWipeFullscreen from "components/animations/CircleWipeFullscreen";
import AnimationPlayer from "components/media/AnimationPlayer";
import OpenQuestionMulti from 'components/Activities/OpenQuestionMulti';
import Encouragement from "components/Activities/Encouragement";
import SummaryTable from 'components/Activities/SummaryTable';
import RiverOfChange from 'components/Activities/RiverOfChange/RiverOfChange';
import {submitAnswer} from "features/course/courseContentSlice";
import {ActivityContext} from "components/course/Activity/ActivityContext";
import wipeService from "features/media/wipeService";
import {useCourseContentData} from "features/course/courseHooks";
import Animation from "components/Activities/Animation";

function getPagerPages(data, group) {
  let activities;
  let children;
  // If activity within group.
  if (group?.id && group?.type === 'course_activity') {
    activities = group.content?.type !== 'group' ? [group] : [];
    children = group.children || [];
  }
  else {
    activities = data.content?.type !== 'group' ? [data] : [];
    children = data.children || [];
  }
  children = children.filter(item => !item.conditional || !item.locked);

  const pages = activities.concat(children);
  const total = pages.length;
  let current = pages.findIndex(item => item.id === data.id);
  if (current === -1) {
    current = 0;
  }

  return {
    pages,
    current,
    total,
  };
}

export default function Activity({data}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // `group` is grouping activity, like group or options, and not always presented.
  const {content, progress, answer, settings} = data;
  const group = useCourseContentData(data?.parent?.id);

  const isComplete = progress && progress.complete >= 1;
  const {pages, current: currentPage, total: totalPages} = getPagerPages(data, group);

  const {voiceover, setVoiceover} = useContext(VoiceoverContext);
  const voiceoverData = content && content.type !== 'video' && (content?.voiceover || (group?.content?.type === 'group' && group.content.voiceover));
  const tips = (content?.tip && content.tip.length > 0) ? content.tip : group?.content?.tip;

  const background = content?.background;
  const allowFullscreen = content?.force_fullscreen && voiceoverData;
  const [fullscreen, setFullscreen] = useState((isFullscreen() || content?.force_fullscreen) && allowFullscreen); // fullscreen control is currently only available with voiceover

  const [isSubmitting, setSubmitting] = useState(false)

  const submit = async (answer = {}) => {
    setSubmitting(true);
    const submitData = {id: data.id, answer: answer};
    const action = await dispatch(submitAnswer(submitData));
    const result = action.payload;

    setSubmitting(false);
    if (action.meta.requestStatus === 'rejected') {
      onError && onError(result);
    }

    return action;
  }

  const handlePrev = () => {
    if (data?.prev?.id) {
      navigate(`/course/content/${data.prev.id}`);
    }
    else {
      data?.course_id ? navigate(`/course/${data.course_id}`) : navigate(`/course`);
    }
  }

  const handleNext = (answer) => {
    submit(answer).then(async (action) => {
      const result = action.payload;
      if (action.meta.requestStatus === 'fulfilled') {
        // Play outro wipes.
        if (result.progress?.complete >= 1 && data.wipe_out) {
          /* @todo this should probably be driven by notifications from back-end, when notifications are added */
          await wipeService.showWipe(data.wipe_out);
        }

        if (result?.next?.id) {
          navigate(`/course/content/${result.next.id}`);
        } else {
          result.course_id ? navigate(`/course/${result.course_id}`) : navigate(`/course`);
        }
      }
    });
  }

  useEffect(() => {
    setVoiceover(voiceoverData);

    if (!voiceoverData) {
      setFullscreen(false);
    }
  }, [voiceoverData]);

  const getActivityComponent = (activityData) => {
    const data = {...activityData};
    if (settings.theme === 'activity' && data.content?.title) {
      data.content = {
        ...data.content,
        title: false,
      };
    }

    const props = {
      data,
    };
    switch (data.content?.type) {
      case 'animation':
        return <Animation {...props}/>
      case 'drag_words':
        return <SelectWord {...props}/>
      case 'open_question':
        return <OpenQuestion {...props}/>
      case 'open_question_multi':
        return <OpenQuestionMulti {...props}/>
      case 'options':
        return <Options {...props}/>
      case 'word_cloud':
        return <WordCloud {...props}/>
      case 'slide':
        return <Slide {...props}/>
      case 'quote':
        return <Quote {...props}/>
      case 'grouped_options':
        return <GroupedOptions {...props}/>
      case 'date':
        return <DateActivity {...props}/>
      case 'scale':
        return <Scale {...props}/>
      case 'video':
        return <Video {...props}/>
      case 'hotspots':
        return <Hotspots {...props}/>
      case 'flow':
        return <Flow {...props}/>
      case 'steps':
        return <Steps {...props}/>
      case 'drag_to_select':
        return <DragToSelect {...props}/>
      case 'drag_to_sequence':
        return <DragToSequence {...props}/>
      case 'drag_to_reorder':
        return <DragToReorder {...props}/>
      case 'accordion':
        return <Accordion {...props}/>
      case 'editable_table':
        return <EditableTable {...props} />
      case 'summary_table':
        return <SummaryTable {...props} />
      case 'encouragement':
        return <Encouragement {...props} />
      case 'river_of_change':
        return <RiverOfChange {...props} />
    }
  }

  const onError = (error) => {
    toast.error(error.message);
  }

  const canNavigate = (page) => {
    return !!(pages[page]?.id && !pages[page]?.locked);
  }

  const setCurrentPage = (page) => {
    if (pages[page]) {
      navigate(`/course/content/${pages[page].id}`);
    }
  }

  const handleFullscreen = () => {
    setFullscreen(!fullscreen);
  }

  const classes = cn('activity');

  return (
    <Box className={classes({
      'full': true,
      'type': content?.type,
      'theme': settings.theme,
      'voiceover': voiceover?.type || false,
      'has-title': !!data?.content?.title,
      'has-description': !!data?.content?.description,
      // @todo Apply class to aside component directly.
      'aside-with-bg': !settings.aside_no_bg,
    })}>
      <ActivityContext.Provider value={{
        data,
        submit,
        canSubmit: true,
        isSubmitting,
        voiceover,
        voiceoverRequired: !(isComplete || answer),
        pagerType: settings.pager,
        totalPages,
        currentPage,
        setCurrentPage,
        canNavigate,
        showFooter: true,
        onPrev: handlePrev,
        onNext: handleNext,
        nextLabel: settings.submit_label,
        toggleFullscreen: allowFullscreen && handleFullscreen,
      }}>
        <CircleWipeFullscreen isOpen={fullscreen}>
          {settings.theme === 'activity' && data?.content?.title && <Text className={classes('title')}>{data.content.title}</Text>}
          {background?.files?.length > 0 && <div className={classes('bg')}>
            <AnimationPlayer files={background.files} muted={background.muted} />
          </div>}
          {getActivityComponent(data)}
          {tips && <Tips tips={tips}/>}
        </CircleWipeFullscreen>
      </ActivityContext.Provider>
    </Box>
  )
}
