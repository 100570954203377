import {useEffect, useState} from "react";
import wipeService from "../../features/media/wipeService";
import {cn} from "app/helpers";
import {batch, effect} from "@preact/signals-react";
import mediaService from "../../features/media/mediaService";
import AnimationPlayer from "./AnimationPlayer";
import {useSelector} from "react-redux";
import {getActiveMedia, getMediaPlaying} from "features/media/mediaSlice";

export const Wipe = () => {
  const classes = cn('wipe');
  const wipe = wipeService.currentWipe.value;
  const activeMedia = useSelector(getActiveMedia);
  const isMediaPlaying = useSelector(getMediaPlaying);
  const [showWipe, setShowWipe] = useState(!!wipe.video);
  const [animationStatus, setAnimationStatus] = useState('idle');
  const inSpeed = wipe?.animation?.in?.speed || 500;
  const outSpeed = wipe?.animation?.out?.speed || 2000;

  const handleComplete = () => {
    animationStatus !== 'fadeout' && setAnimationStatus('fadeout');
  };

  const handleError = () => {
    handleComplete();
  };

  effect(() => {
    if (wipe.video && !showWipe) {
      setShowWipe(true);
      setAnimationStatus('mount');
    }
  });

  useEffect(() => {
    if (animationStatus === 'mount') {
      setAnimationStatus('fadein');
    }
  }, [animationStatus]);

  useEffect(() => {
    if (showWipe) {
      batch(() => {
        mediaService.allowAutoplay.value = false;
      });
    }
    else {
      mediaService.allowAutoplay.value = true;
    }
  }, [showWipe]);

  useEffect(() => {
    if (showWipe && isMediaPlaying && activeMedia) {
      activeMedia.pause();
    }
  }, [isMediaPlaying, activeMedia, showWipe]);

  const transition = 'opacity ' + (animationStatus === 'fadein' || animationStatus === 'mount' ? (inSpeed / 1000).toFixed(3) : (outSpeed / 1000).toFixed(3)) + 's ease';

  return showWipe && <div className={classes({
    animation: animationStatus,
  })} style={{
    opacity: (['mount', 'fadeout'].includes(animationStatus) || !showWipe) ? 0 : 1,
    transition: transition,
  }} onTransitionEnd={() => {
    if (animationStatus === 'fadeout') {
      const nextWipe = wipeService.wipeQueue.value.shift();
      if (nextWipe) {
        wipeService.currentWipe.value = nextWipe;
      } else {
        batch(() => {
          mediaService.allowAutoplay.value = true;
          wipeService.currentWipe.value = {};
        });
      }
      setAnimationStatus('idle');
      setShowWipe(false);
    }

    if (animationStatus === 'fadein') {
      setAnimationStatus('idle');
    }
  }}>
    <AnimationPlayer key={'wipe-' + wipe?.id} files={wipe.video} loop={false} onComplete={handleComplete} onError={handleError} muted={!!wipe.muted} onCompleteOffset={outSpeed} />
  </div>
}
