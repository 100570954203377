import {useFormik} from "formik"
import {passwordSchema} from "../../schemas/index"
import OutlineButton from "../../buttons/OutlineButton"
import {useDispatch, useSelector} from 'react-redux'
import {changePassword} from "features/auth/authSlice"
import PasswordInput from '../PasswordInput'
import {Text} from '@chakra-ui/react';
import {useEffect} from 'react'
import {toast} from 'react-toastify'

const EditProfileForm = ({isLoading}) => {
  const dispatch = useDispatch()
  const {isError, message} = useSelector((state) => state.auth)

  const onSubmit = async (values) => {
    dispatch(changePassword(values)).then(action => {
      if (action?.meta?.requestStatus === 'fulfilled') {
        toast.success("Password updated");
        resetForm();
      }
    })
  }

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError, message])


  const {values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm} = useFormik({
    initialValues: {
      existingPassword: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: passwordSchema,
    onSubmit,
  })


  return (
    <form onSubmit={handleSubmit}>
      <PasswordInput touched={touched} errors={errors} id={"existingPassword"} name={"existingPassword"}
                     type={"password"} value={values.existingPassword} onBlur={handleBlur} placeholder={""}
                     onChange={handleChange} label={"Existing Password"}/>
      <PasswordInput touched={touched} errors={errors} id={"password"} name={"password"} type={"password"}
                     value={values.password} onBlur={handleBlur} placeholder={""} onChange={handleChange}
                     label={"New Password"}/>
      <Text color={'secondary.darkblue'} fontSize={'sm'}>Must be at least 8 characters and contain at least one number,
        one uppercase and one lowercase letter.</Text>
      <PasswordInput touched={touched} errors={errors} id={"confirmPassword"} name={"confirmPassword"}
                     type={"confirmPassword"} value={values.confirmPassword} onBlur={handleBlur} placeholder={""}
                     onChange={handleChange} label={"Confirm New Password"}/>
      <OutlineButton _hover={{color: 'white', bg: 'primary.navy'}} isLoading={isLoading} type={"submit"} name={"submit"}
                     copy={"Update"} txColor={'white'} bgColor={'primary.navy'}/>
    </form>
  )
}

export default EditProfileForm

