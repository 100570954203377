import {cn, triggerDownload} from "app/helpers";
import RichText from "../Activities/RichText";
import LockedLabel from "../labels/LockedLabel";
import {
  Button, ButtonGroup, Text,
  useDisclosure
} from "@chakra-ui/react";
import DownloadIcon from "../../icons/DownloadIcon";
import ErrorIcon from "../../icons/ErrorIcon";
import ModalDialog from "../modals/ModalDialog";
import ModalButton from "../modals/ModalButton";
import {useNavigate} from "react-router-dom";
import LockedIcon from "../../icons/LockedIcon";
import HelpIcon from "../../icons/HelpIcon";

export default function ResourceCard({data}) {
  const navigate = useNavigate();
  const classes = cn('resource');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const file = data.file?.file;
  const fileName = data.file?.name;

  const handleDownload = () => {
    if (file) {
      if (data.declaration) {
        onOpen();
      }
      else {
        triggerDownload(file.url, fileName, file.mime);
      }
    }
  };

  const handleDownloadFinal = () => {
    if (file) {
      triggerDownload(file.url, fileName, file.mime);
    }
  };

  return <div className={classes({'card': true, 'locked': data.locked})}>
    <div className={classes('content')}>
      {data.title && <div className={classes('title')}>
        {data.title}
      </div> }
      {data.body && <div className={classes('description')}>
        <RichText text={data.body.value}/>
      </div>}
    </div>
    <div className={classes('footer')}>
      {data.locked ? <LockedLabel/> : <Button leftIcon={<DownloadIcon/>} onClick={handleDownload} variant={'solidOrange'}>Download</Button>}
      {data.locked && <ModalButton className={'button--help'} text={<HelpIcon/>} modalTitle="Locked" modalIcon={<LockedIcon size={32}/>} modalFooter={
        <ButtonGroup spacing="0" flexWrap="wrap" gap={4} width="100%">
          <Button variant={'solidOrange'} onClick={() => {navigate('/course')}}>Resume Course</Button>
        </ButtonGroup>
      }>
        <Text>In order to download this resource please complete the relevant course content.</Text>
      </ModalButton>}
    </div>

    <ModalDialog title="Declaration" icon={<ErrorIcon/>} isOpen={isOpen} onClose={onClose} footer={
      <ButtonGroup spacing="0" flexWrap="wrap" gap={4} width="100%">
        <Button leftIcon={<DownloadIcon/>} variant={'solidNavy'} onClick={handleDownloadFinal}>Agree & Download</Button>
        {data.help && <ModalButton className={'button--help'} text={<HelpIcon/>}>
          <RichText text={data.help}/>
        </ModalButton>}
      </ButtonGroup>
    }>
      <RichText text={data.declaration}/>
    </ModalDialog>
  </div>
}
