export default function ProgressBar({media, currentTime = 0, duration = 0}) {
    const onChange = (event) => {
        media.currentTime = event.target.value / 100;
    };

    const makeColor = (color, fract) => {
        return color + ' ' + (fract * 100) + '%';
    }

    const gradient = [
        'to right',
        makeColor('#FF9165', 0),
        makeColor('#FF9165', duration > 0 ? currentTime / duration : 0),
        makeColor('#E2E2E2', duration > 0 ? currentTime / duration : 0),
        makeColor('#E2E2E2', 1),
    ];

    return <div className="player-controls__progress">
        <input type="range" min={0} max={Math.ceil(duration * 100)} value={Math.ceil(currentTime * 100)} onChange={onChange} style={{
            background: 'linear-gradient(' + gradient.join(',') + ')',
        }}/>
    </div>
}
