import { useFormik } from "formik"
import { newPasswordRequestSchema } from "../schemas"
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { requestNewPassword } from '../features/auth/authSlice'

import { Center, Text, Heading, VStack } from '@chakra-ui/react'


import FormField from "../components/Input"
import Btn from "../components/Button";
import { useEffect } from "react";
import { toast } from 'react-toastify'



export default function NewPasswordForm({setComponent}) {

    const navigate = useNavigate()

    const { isLoading, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    )
      
    const dispatch = useDispatch()
    
    useEffect(() => {
        if (isError) {
            toast.error(message)
        }
        if (isSuccess) {
           setComponent('resend-link')
        }
    })


    const goBack = () => {
        navigate('/login')
    }

    const onSubmit = async (values) => {
       dispatch(requestNewPassword(values))
    }
    
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
      initialValues: {
          email: ""
      },
      validationSchema: newPasswordRequestSchema,
      onSubmit,
    })
  
    return (
      <>
        <Heading mb={2} color={'primary.navy'} as={'h2'}>Request a new password</Heading>
        <Text color={'primary.navy'}>Enter your email address and we will send a password reset link.</Text>
        <form onSubmit={handleSubmit}>
          <FormField touched={touched} errors={errors} id={"email"}  name={"email"} type={"text"} value={values.email} placeholder={""} onBlur={handleBlur} onChange={handleChange} label={"Email"} />
          <Center mx={'auto'} mt={'46px'}>
            <VStack>
              <Btn isLoading={isLoading} type={"submit"} name={"submit"} copy={"Reset Password"} txColor={'white'} bgColor={'primary.navy'}  />
              <Btn onClick={goBack} name={"submit"} copy={"Back"} variant={'outline'} colorScheme={'primary.navy'} />
            </VStack>
          </Center>
          
          
        </form>
      </>
    )
}