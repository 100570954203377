import WordCloud from 'react-wordcloud';
import { Box  } from '@chakra-ui/react';
import RichText from "./RichText";


const WordCloudRender = ({values, cloudDescription}) => {

   const data = values.map(({ value, weight }) => ({ text: value,  value: weight }));
  
    const options = {
        colors: ['#FFDED1'],
        enableTooltip: false,
        deterministic: true,
        padding: 3,
        scale: 'sqrt',
        spiral: 'archimedean',
        transitionDuration: 1000,
        rotations: 0,
        fontSizes: [15, 60],
        fontFamily: 'Lora',
        fontWeight: 'bolder',
    }

    return (
        <>
        <RichText text={cloudDescription}/>
        <Box>
            <WordCloud
                words={data}
                options={options}

            />
        </Box>
        </>
    )
}

export default WordCloudRender